import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Menu, Image, Dropdown, Row, Col, Modal, Select } from 'antd';
import {
   AiOutlineHome,
   AiOutlineMenuFold,
   AiOutlineMenuUnfold,
   AiOutlineLogout,
} from 'react-icons/ai';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { logout } from '../features/auth/authSlice';
import { BiTransfer, BiTime } from 'react-icons/bi';
import { HiClipboardList, HiUserAdd } from 'react-icons/hi';
import { FaRegUserCircle, FaKey, FaUsers, FaUser, FaWallet } from 'react-icons/fa';
import styled from 'styled-components';
import { MdAccountBalanceWallet, MdSupportAgent } from 'react-icons/md';

import { BsFillCartFill, BsFillCartPlusFill } from 'react-icons/bs';

import { useMediaQuery } from 'react-responsive';
import { getTotals } from '../features/cartSlice';

import { getPV } from '../features/ewallet/ewalletSlice';
import { setLanguage } from '../features/languageSlice';

import Cookies from 'js-cookie';

import UserLogo2 from '../assets/icon/user-logo2.png';
import moment from 'moment-timezone';
import commaNumber from 'comma-number';

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
   return {
      key,
      icon,
      children,
      label,
      type,
   };
}

const DefaultLayout = (props) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet, isError: isEwalletError } = useSelector(
      (state) => state.ewallet
   );

   const [modalAddLine, setModalAddLine] = useState(false);

   const [isLogout, setIsLogout] = useState(false);

   const cart = useSelector((state) => state.cart);

   const lang = useSelector((state) => state.language.lang);

   const { cartItems } = cart;

   const [collapsed, setCollapsed] = useState(false);
   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   const isMobile = useMediaQuery({ maxWidth: 770 });

   useEffect(() => {
      if (isMobile) {
         setCollapsed(!collapsed);
      }
   }, [isMobile]);

   useEffect(() => {
      if (isLogout) {
         dispatch(logout());
      }
      // LOGOUT
      dispatch(getTotals());

      const currentTimeInThailand = moment().tz('Asia/Bangkok');

      //   console.log('Current time in Thailand (with offset):', currentTimeInThailand.format('YYYY-MM-DD HH:mm Z'));
      // const currentTimeInThailand = moment().tz('Asia/Bangkok');

      // Define the login restriction time window in Thailand time zone
      const loginStartTime = moment({ hour: 23, minute: 45 }).tz(
         'Asia/Bangkok'
      );
      const loginEndTime = moment({ hour: 7, minute: 0 }).tz('Asia/Bangkok');

      if (
         currentTimeInThailand.isAfter(loginStartTime) ||
         currentTimeInThailand.isBefore(loginEndTime)
      ) {
         dispatch(logout());
      }

      dispatch(getPV());
   }, [cartItems, dispatch, isEwalletError, navigate]);

   let eWallet2 = null;

   if (ewallet) {
      eWallet2 = ewallet.pv;
   }

   const logoutHandler = () => {
      dispatch(logout());
      navigate('/login');
   };

   const handlerLangChange = (value) => {
      //  localStorage.setItem('lang', value);
      dispatch(setLanguage(value));
      // setLang(value);
   };

   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: (
                  <>
                     <Row>
                        <Col span={8}>
                           <Image
                              src={UserLogo2}
                              style={{
                                 marginTop: '20px',
                              }}
                              preview={false}
                              width={55}
                           />
                        </Col>
                        <Col span={14}>
                           <p> {userInfo?.username}</p>
                           <p>รหัส:{userInfo?.userId} </p>
                           <Link to='/profile'>
                              {' '}
                              <button className='btn-danger'>
                                 {' '}
                                 แก้ไขข้อมูลส่วนตัว{' '}
                              </button>{' '}
                           </Link>
                        </Col>
                     </Row>
                     <hr />
                  </>
               ),
            },
            {
               key: '2',
               label: <p>ข้อมูลส่วนตัว</p>,
               icon: <FaRegUserCircle />,
            },
            {
               key: '3',
               label: <p>PV: {commaNumber(eWallet2)}</p>,
               icon: <MdAccountBalanceWallet />,
            },
            {
               key: '4',
               label: <p>ewallet: {commaNumber(ewallet.eWallet)}</p>,
               icon: <MdAccountBalanceWallet />,
            },
            {
               key: '5',
               label: <p onClick={() => setModalAddLine(true)}>ติดต่อเรา</p>,
               icon: <MdSupportAgent />,
            },

            {
               key: '6',
               danger: true,
               label: (
                  <>
                     <p
                        style={{
                           borderTop: '1px solid gray',
                           marginBottom: '10px !important',
                        }}
                        onClick={logoutHandler}
                     >
                        {' '}
                        Logout{' '}
                     </p>
                     <hr />
                  </>
               ),
            },
         ]}
      />
   );

   const getItemCenter = (label, key, icon, children) => ({
      key,
      icon,
      children,
      label,
   });

   const text = {
      TH: {
         dashboard: 'แดชบอร์ด',
         profile: 'แก้ไขข้อมูลส่วนตัว',
         binary: 'สายงานไบนารี่',
         bonusHistory: 'โบนัส',
         transferEwallet : 'โอน ewallet',
         ewallet: 'แปลง ewallet',
         ewalletHistory: 'ประวัติ ewallet',
         transferPV: 'โอน PV',
         transferRecord: 'ประวัติ PV',
         depositPV: 'เติม PV',
         productPrice: 'รายการสินค้า',
         depositRecord: 'ประวัติการเติม PV',
         newMember: 'สมัครสมาชิกใหม่',
         order: 'ซื้อซ้ำ/Topup',
         orders: 'รายการสั่งซื้อ',
         updatePassword: 'เปลี่ยนรหัสผ่าน',
         logout: 'ออกจากระบบ',
      },
      ENG: {
         dashboard: 'Dashboard',
         profile: 'Edit Profile',
         binary: 'Binary Work',
         bonusHistory: 'Bonus History',
         transferPV: 'Transfer PV',
         transferEwallet : 'transferEwallet',
         ewallet: 'Convert Ewallet',
         ewalletHistory: 'Ewallet History',
         transferRecord: 'PV Record',
         depositPV: 'Deposit PV',
         productPrice: 'Product List',
         depositRecord: 'PV Deposit Record',
         newMember: 'Register New Member',
         order: 'Repurchase/Topup',
         orders: 'Order List',
         updatePassword: 'Change Password',
         logout: 'Logout',
      },
      KH: {
         dashboard: 'ផ្ទាំងគ្រប់គ្រង',
         profile: 'កែប្រែព័ត៌មានផ្ទាល់ខ្លួន',
         binary: 'ការងារកូនីទ្វេ',
         bonusHistory: 'ប្រវត្តិរង្វាន់',
         transferPV: 'ផ្ទេរ PV',
         transferRecord: 'ប្រវត្តិ PV',
         ewallet: 'Convert Ewallet',
         depositPV: 'ដាក់ប្រាក់ PV',
         depositRecord: 'ប្រវត្តិការដាក់ប្រាក់ PV',
         newMember: 'ចុះឈ្មោះសមាជិកថ្មី',
         order: 'ទិញម្តងទៀត/បញ្ចូលលុយ',
         orders: 'បញ្ជីបញ្ជា',
         updatePassword: 'ផ្លាស់ប្ដូរពាក្យសម្ងាត់',
         logout: 'ចេញពីប្រព័ន្ធ',
      },
   };

   const items = [
      getItemCenter(
         <Link to='/'>
            <span>{text[lang].dashboard}</span>
         </Link>,
         '/',
         <AiOutlineHome color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/profile'>
            <span>{text[lang].profile}</span>
         </Link>,
         '/profile',
         <FaUser color='#ffca00' />
      ),
      getItemCenter(
         <Link to={`/binary/${userInfo?.userId}/${userInfo?.username}`}>
            <span>{text[lang].binary}</span>
         </Link>,
         '/binary',
         <FaUsers color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/bonusHistory'>
            <span>{text[lang].bonusHistory}</span>
         </Link>,
         '/bonusHistory',
         <RiMoneyDollarCircleFill color='#ffca00' />
      ),
      getItemCenter('ewallet', 'sub2', <FaWallet color='#ffca00' />, [
         getItemCenter(
            <Link to='/transferEwallet'>
               <span>{text[lang].transferEwallet}</span>
            </Link>,
            '/transferEwallet',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/ewallet'>
               <span>{text[lang].ewallet}</span>
            </Link>,
            '/ewallet',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/ewalletHistory'>
               <span>{text[lang].ewalletHistory}</span>
            </Link>,
            '/ewalletHistory',
            <BiTime color='#ffca00' />
         ),
      
      ]),
      getItemCenter('PV', 'sub3', <FaUsers color='#ffca00' />, [
         getItemCenter(
            <Link to='/transfer'>
               <span>{text[lang].transferPV}</span>
            </Link>,
            '/transfer',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/transferrecord'>
               <span>{text[lang].transferRecord}</span>
            </Link>,
            '/transferrecord',
            <BiTime color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/deposit'>
               <span>{text[lang].productPrice}</span>
            </Link>,
            '/deposit',
            <BsFillCartFill color='#ffca00' />
         ),
      ]),
      getItemCenter(
         <Link
            to='/register'
            state={{
               userId: '',
               username: '',
               side: '',
            }}
         >
            <span>{text[lang].newMember}</span>
         </Link>,
         '/register',
         <HiUserAdd color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/order'>
            <span>{text[lang].order}</span>
         </Link>,
         '/order',
         <Link to='/order'>
            <BsFillCartPlusFill color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <Link to='/orders'>
            <span>{text[lang].orders}</span>
         </Link>,
         '/orders',
         <Link to='/orders'>
            <HiClipboardList color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <Link to='/updatePassword'>
            <span>{text[lang].updatePassword}</span>
         </Link>,
         '/updatePassword',
         <Link to='/updatePassword'>
            <FaKey color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <span onClick={logoutHandler}>{text[lang].logout}</span>,
         '/logout',
         <AiOutlineLogout color='#ffca00' />
      ),
   ];

   const itemsCenter = [
      getItemCenter(
         <Link to='/'>
            <span>{text[lang].dashboard}</span>
         </Link>,
         '/',
         <AiOutlineHome color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/profile'>
            <span>{text[lang].profile}</span>
         </Link>,
         '/profile',
         <FaUser color='#ffca00' />
      ),
      getItemCenter(
         <Link to={`/binary/${userInfo?.userId}/${userInfo?.username}`}>
            <span>{text[lang].binary}</span>
         </Link>,
         '/binary',
         <FaUsers color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/bonusHistory'>
            <span>{text[lang].bonusHistory}</span>
         </Link>,
         '/bonusHistory',
         <RiMoneyDollarCircleFill color='#ffca00' />
      ),

      getItemCenter('ewallet', 'sub2', <FaUsers color='#ffca00' />, [
         getItemCenter(
            <Link to='/transferEwallet'>
               <span>{text[lang].transferEwallet}</span>
            </Link>,
            '/transferEwallet',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/ewallet'>
               <span>{text[lang].ewallet}</span>
            </Link>,
            '/ewallet',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/ewalletHistory'>
               <span>{text[lang].ewalletHistory}</span>
            </Link>,
            '/ewalletHistory',
            <BiTime color='#ffca00' />
         ),
      
      ]),

    
      getItemCenter('PV', 'sub3', <FaUsers color='#ffca00' />, [
         getItemCenter(
            <Link to='/transfer'>
               <span>{text[lang].transferPV}</span>
            </Link>,
            '/transfer',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/transferrecord'>
               <span>{text[lang].transferRecord}</span>
            </Link>,
            '/transferrecord',
            <BiTime color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/deposit'>
               <span>{text[lang].depositPV}</span>
            </Link>,
            '/deposit',
            <BiTransfer color='#ffca00' />
         ),
         getItemCenter(
            <Link to='/depositrecord'>
               <span>{text[lang].depositRecord}</span>
            </Link>,
            '/depositrecord',
            <BiTime color='#ffca00' />
         ),
      ]),
      getItemCenter(
         <Link
            to='/register'
            state={{
               userId: '',
               username: '',
               side: '',
            }}
         >
            <span>{text[lang].newMember}</span>
         </Link>,
         '/register',
         <HiUserAdd color='#ffca00' />
      ),
      getItemCenter(
         <Link to='/order'>
            <span>{text[lang].order}</span>
         </Link>,
         '/order',
         <Link to='/order'>
            <BsFillCartPlusFill color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <Link to='/orders'>
            <span>{text[lang].orders}</span>
         </Link>,
         '/orders',
         <Link to='/orders'>
            <HiClipboardList color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <Link to='/updatePassword'>
            <span>{text[lang].updatePassword}</span>
         </Link>,
         '/updatePassword',
         <Link to='/updatePassword'>
            <FaKey color='#ffca00' />
         </Link>
      ),
      getItemCenter(
         <span onClick={logoutHandler}>{text[lang].logout}</span>,
         '/logout',
         <AiOutlineLogout color='#ffca00' />
      ),
   ];

   return (
      <>
         <div>
            {userInfo?.isCenter ? (
               <Layout>
                  <Sider
                     trigger={null}
                     breakpoint='md'
                     collapsedWidth={0}
                     collapsible
                     collapsed={collapsed}
                  >
                     <div className='logo py-2 mb-2 mt-3 text-center'>
                        {/* <b className='text-light'>
                        <span> รหัส:</span> {userInfo.userId}{' '}
                     </b>
                     <br />
                     <b className='text-light'> {userInfo.username} </b> */}
                        <span className='text-light text-center'>
                           <b>SIAMPRAI INTER</b>
                        </span>
                        <hr style={{ color: 'white' }} />
                     </div>
                     <div className='text-center'>
                        <Image
                           src={UserLogo2}
                           width={100}
                           height={100}
                           preview={false}
                        />
                        <p style={{ margin: '0' }} className='text-light mt-4'>
                           <span> รหัส:</span> {userInfo?.userId}{' '}
                        </p>

                        <p style={{ margin: '0' }} className='text-light'>
                           {' '}
                           {userInfo?.username}{' '}
                        </p>

                        <p style={{ margin: '0' }} className='text-light'>
                           {' '}
                           {userInfo?.role}{' '}
                        </p>
                     </div>
                     <Menu
                        defaultSelectedKeys={window.location.pathname}
                        mode='inline'
                        theme='dark'
                        inlineCollapsed={collapsed}
                        items={itemsCenter}
                     />
                  </Sider>

                  <Layout className='site-layout'>
                     <Header
                        className='d-flex site-layout-background'
                        // style={{ padding: 10 }}
                     >
                        {React.createElement(
                           collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                           {
                              className: 'trigger',
                              onClick: toggleCollapsed,
                           }
                        )}

                        <div className='d-flex align-items-center'>
                           <h5 className='my-1 ewallet-header'>
                              <span
                                 style={{
                                    // display: collapsed ? 'none' : '',
                                    color: '#ffca00',
                                 }}
                              >
                                 PV :
                              </span>
                              <b
                                 style={{
                                    fontSize: collapsed ? '15px' : '',
                                    color: '#ffca00',
                                 }}
                              >
                                 {' '}
                                 {eWallet2 ? eWallet2.toLocaleString() : 0}{' '}
                              </b>
                           </h5>
                           <h5 className='my-1 ewallet-header'>
                              <span
                                 style={{
                                    // display: collapsed ? 'none' : '',
                                    color: '#ffca00',
                                 }}
                              >
                                 ewallet :
                              </span>
                              <b
                                 style={{
                                    fontSize: collapsed ? '15px' : '',
                                    color: '#ffca00',
                                 }}
                              >
                                 {' '}
                                 {ewallet.eWallet
                                    ? ewallet.eWallet.toLocaleString()
                                    : 0}{' '}
                              </b>
                           </h5>

                           <div className='mx-3' style={{}}>
                              <Select
                                 style={{
                                    width: '100px',
                                    background: 'transparent',
                                    color: 'black',
                                    position: 'relative',
                                    zIndex: '9999999',
                                 }}
                                 onChange={(value) => handlerLangChange(value)}
                                 placeholder='ภาษา'
                                 options={[
                                    {
                                       value: 'TH',
                                       label: 'TH',
                                    },
                                    {
                                       value: 'ENG',
                                       label: 'ENG',
                                    },
                                    {
                                       value: 'KH',
                                       label: 'KH',
                                    },
                                 ]}
                              ></Select>
                           </div>

                           <Dropdown overlay={menu}>
                              <a onClick={(e) => e.preventDefault()}>
                                 <h4 className='mx-2 my-1'>
                                    <Image
                                       src={UserLogo2}
                                       width={32}
                                       height={32}
                                       preview={false}
                                    />
                                 </h4>
                              </a>
                           </Dropdown>
                        </div>
                     </Header>
                     <div className=''>
                        {' '}
                        <Content className='site-layout-background'>
                           {props.children}
                        </Content>
                     </div>
                  </Layout>
               </Layout>
            ) : (
               <Layout>
                  <Sider
                     trigger={null}
                     breakpoint='md'
                     collapsedWidth={0}
                     collapsible
                     collapsed={collapsed}
                  >
                     <div className='logo py-2 mb-2 mt-3 text-center'>
                        <span className='text-light text-center'>
                           <b>SIAMPRAI INTER</b>
                        </span>
                        <hr style={{ color: 'white' }} />
                     </div>
                     <div className='text-center'>
                        <Image
                           src={UserLogo2}
                           width={100}
                           height={100}
                           preview={false}
                        />
                        <p style={{ margin: '0' }} className='text-light mt-4'>
                           <span> รหัส:</span> {userInfo?.userId}{' '}
                        </p>

                        <p style={{ margin: '0' }} className='text-light'>
                           {' '}
                           {userInfo?.username}{' '}
                        </p>

                        <p style={{ margin: '0' }} className='text-light'>
                           {' '}
                           {userInfo?.role}{' '}
                        </p>
                     </div>
                     <Menu
                        defaultSelectedKeys={window.location.pathname}
                        mode='inline'
                        theme='dark'
                        inlineCollapsed={collapsed}
                        items={items}
                     />
                  </Sider>

                  <Layout className='site-layout'>
                     <Header className='d-flex site-layout-background'>
                        {React.createElement(
                           collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                           {
                              className: 'trigger',
                              onClick: toggleCollapsed,
                           }
                        )}

                        <div className='d-flex align-items-center'>
                           <div className='d-flex gap-3'>
                              <h5 className='my-1 ewallet-header'>
                                 <span
                                    style={{
                                       //   display: !collapsed ? 'none' : '',
                                       color: '#ffca00',
                                    }}
                                 >
                                    pv :
                                 </span>
                                 <b
                                    style={{
                                       fontSize: collapsed ? '15px' : '',
                                       color: '#ffca00',
                                    }}
                                 >
                                    {' '}
                                    {eWallet2
                                       ? eWallet2.toLocaleString()
                                       : 0}{' '}
                                 </b>
                              </h5>
                            
                              <h5 className='my-1 ewallet-header'>
                                 <span
                                    style={{
                                       // display: collapsed ? 'none' : '',
                                       color: '#ffca00',
                                    }}
                                 >
                                    ewallet :
                                 </span>
                                 <b
                                    style={{
                                       fontSize: collapsed ? '15px' : '',
                                       color: '#ffca00',
                                    }}
                                 >
                                    {' '}
                                    {ewallet.eWallet
                                       ? ewallet.eWallet.toLocaleString()
                                       : 0}{' '}
                                 </b>
                              </h5>
                           </div>

                           <div className='mx-3'>
                              <Select
                                 style={{
                                    width: '100px',
                                    background: 'transparent',
                                    color: 'black',
                                 }}
                                 onChange={(value) => handlerLangChange(value)}
                                 placeholder='ภาษา'
                                 options={[
                                    {
                                       value: 'TH',
                                       label: 'TH',
                                    },
                                    {
                                       value: 'ENG',
                                       label: 'ENG',
                                    },
                                    {
                                       value: 'KH',
                                       label: 'KH',
                                    },
                                 ]}
                              ></Select>
                           </div>

                           <Dropdown overlay={menu}>
                              <a onClick={(e) => e.preventDefault()}>
                                 <h4 className='mx-2 my-1'>
                                    <Image
                                       src={UserLogo2}
                                       width={32}
                                       height={32}
                                       preview={false}
                                    />
                                 </h4>
                              </a>
                           </Dropdown>
                        </div>
                     </Header>
                     <div className=''>
                        {' '}
                        <Content
                           className='site-layout-background'
                           // style={{
                           //    margin: '10px',
                           //    padding: 24,
                           //    minHeight: '80vh',
                           // }}
                        >
                           {props.children}
                        </Content>
                     </div>
                  </Layout>
               </Layout>
            )}
         </div>
         <Modal
            title='ติดต่อเจ้าหน้าที่'
            visible={modalAddLine}
            onCancel={() => setModalAddLine(false)}
            footer={false}
         >
            <div className='text-center'>
               <div>
                  <h4>
                     <b>สยามไพรพลัส อินเตอร์</b>
                  </h4>
               </div>
               <img
                  src='https://qr-official.line.me/gs/M_439yvcnx_GW.png?oat_content=qr'
                  alt='QR code'
                  style={{
                     maxWidth: '100%',
                     height: 'auto',
                     margin: '0 auto',
                     display: 'block',
                  }}
               />
            </div>
            <h5 className='text-center mt-2'>
               <b>สแกน QR Code เพื่อเพิ่มเพื่อน</b>
            </h5>
         </Modal>
      </>
   );
};

export default DefaultLayout;

const LineButton = styled.button`
   -webkit-appearance: button;
   -webkit-writing-mode: horizontal-tb !important;
   text-rendering: auto;
   padding: 20px;
   border-radius: 5px;
   background-color: #06c755;
   align-items: flex-start;
   padding: 5px 14px 6px;
   border: none;
   cursor: pointer;
   text-align: center;
   color: white;

   &:hover {
      background-color: #06c756a1;
      color: #666;
   }

   &:active {
      background-color: #06c755bb;
      color: #000000;
   }
`;
