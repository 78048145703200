import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Button, Table } from 'antd';
import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
   getCommission,
   getRecommendCommission,
} from '../features/commission/commissionSlice';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BiRightArrowAlt } from 'react-icons/bi';
import { getSetting } from '../features/setting/settingSlice';
import { Container } from 'react-bootstrap';
import commaNumber from 'comma-number';

const BonusHistory = () => {
   const [loadingPage, setLoadingPage] = useState(true);

   const lang = useSelector((state) => state.language.lang);

   const [isBinary, setIsBinary] = useState(true);
   const [isMatch, setIsMatch] = useState(false);
   const [isMobile, setIsMobile] = useState(false);

   const { settings } = useSelector((state) => state.setting);
   const { commissions, reccommendCommsion } = useSelector(
      (state) => state.commission
   );

   let keyPrice = null;
   let transferFee = null;
   let tax = null;
   let KHpercent = null;
   let KHprice = null;

   if (settings) {
      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

      KHpercent = settings
         .filter((obj) => obj.name.includes('percenKH'))
         .map((obj) => obj.number);

      KHprice = settings
         .filter((obj) => obj.name.includes('KHprice'))
         .map((obj) => obj.number);

      transferFee.toString();
      tax.toString();
      KHpercent.toString();
      KHprice.toString();
   }

   let taxInt = parseInt(tax);
   let KHpercentInt = parseInt(KHpercent);
   let KHpriceInt = parseInt(KHprice);

   let KHpercentInt2 = 0;
   if (KHpercentInt) KHpercentInt2 += KHpercentInt;

   const dispatch = useDispatch();

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getCommission());

      dispatch(getRecommendCommission());
      dispatch(getSetting());
   }, []);

   const { result, result2 } = reccommendCommsion;

   const { commission, user, bonusMobile } = commissions;

   let mm = [];

   let rr = [];

   let comMobile = [];

   if (bonusMobile?.length >= 1) {
      // Function to sum amounts by date and format into an array of objects
      function sumAmountsByDate(bonusMobile) {
         const sums = {};

         bonusMobile.forEach((entry) => {
            if (entry.userRegister && entry.userRegister?.userId) {
               // Check if userRegister exists and userId is not null

               const date = entry.date.split('T')[0]; // Extracting date from ISO string

               const amount = entry.amount;

               if (!sums[date]) {
                  sums[date] = amount;
               } else {
                  sums[date] += amount;
               }
            }
         });

         // Convert sums object into an array of objects
         const result = [];
         for (const date in sums) {
            result.push({ date, amount: sums[date] });
         }

         return result;
      }

      comMobile = sumAmountsByDate(bonusMobile);
   }

   let mergedArray = [];
   if (commission?.length >= 1 || result) {
      //merge reuslt1 antd result2

      // Merge arrays

      const position = user?.position;
      result?.forEach((entry1) => {
         let matchingEntry = result2.find(
            (entry2) => entry2.date === entry1.date
         );
         if (matchingEntry) {
            mergedArray.push({
               date: entry1.date,
               position, // Adding position here
               amount2: entry1.amount2 + matchingEntry.amount2,
               amount22: entry1.amount2,
               amount23: matchingEntry.amount2,
            });
         } else {
            mergedArray.push({ ...entry1, position }); // Adding position here
         }
      });

      // Add entries from result2 that are not present in result1
      result2?.forEach((entry2) => {
         if (!result.some((entry1) => entry1.date === entry2.date)) {
            mergedArray.push({
               ...entry2,
               amount22: 0,
               amount23: entry2.amount2,
               position,
            }); // Adding position here
         }
      });

      // Sort merged array by date
      mergedArray.sort((a, b) => new Date(a.date) - new Date(b.date));
      mergedArray.reverse();
      //merge reuslt1 antd result2

      rr = result?.map((r1) => {
         const rr2 = result2.find(
            (r2) =>
               r1.date.toString().substring(0, 10) ===
               r2.date.toString().substring(0, 10)
         );
         return { ...r1, r2: rr2 };
      });

      rr?.filter(function (element) {
         return element.r2 !== undefined;
      });

      rr?.filter((e) => {
         return Object.keys(e).forEach((key) => {
            if (e[key] === undefined) {
               return e[key] === 0;
            }
         });
      });

      for (let i = 0; i < commission?.length; i++) {
         if (commission[i].amount) {
            mm.push({
               ...commission[i],
               position,
            });
         }
      }
   }

   mm.forEach((entry) => {
      entry.date = new Date(entry.date).toISOString().split('T')[0];
   });

   // Create a dictionary from the 'mm' array for quick lookup
   const mmDict = {};
   mm.forEach((entry) => {
      if (!mmDict[entry.date]) {
         mmDict[entry.date] = [];
      }
      mmDict[entry.date].push(entry);
   });

   // Add or update the dictionary with 'comMo' entries
   comMobile.forEach((entry) => {
      if (mmDict[entry.date]) {
         mmDict[entry.date].forEach((mmEntry) => {
            mmEntry.comMo_amount = (mmEntry.comMo_amount || 0) + entry.amount;
         });
      } else {
         mmDict[entry.date] = [
            {
               date: entry.date,
               comMo_amount: entry.amount,
            },
         ];
      }
   });

   // Convert the dictionary back to a list and ensure the correct order
   const combined = [];
   Object.keys(mmDict)
      .sort()
      .forEach((date) => {
         combined.push(...mmDict[date]);
      });

   // Output the combined list

   console.log('mm:', mm);

   let columns = null;

   if (isBinary) {
      columns = [
         {
            title: 'ลำดับ',
            dataIndex: 'depth',
            key: 'depth',
            responsive: ['sm'],
            render: (text, record, index) => <span>{index + 1}</span>,
            width: '2%',
         },
         {
            title: 'วันที่',
            dataIndex: 'date',
            width: '2%',
            key: 'date',
            render: (date) => (
               <div>
                  {' '}
                  <span style={{ fontSize: '14px' }}>
                     <b>{moment(date).format('DD/MM/YYYY')}</b>
                  </span>{' '}
               </div>
            ),
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
         },

         {
            title: 'STEP',
            width: '2%',
            dataIndex: ['stepTo, stepFrom'],
            render: (_, row) => (
               <>
                  <span style={{ fontSize: '14px' }}>
                     <b>
                        {row['stepFrom'] === 0
                           ? row['stepFrom'] + 1
                           : row['stepFrom']}
                     </b>
                  </span>
                  <span>
                     {' '}
                     <BiRightArrowAlt />{' '}
                  </span>{' '}
                  <span style={{ fontSize: '14px' }}>
                     <b> {row['stepTo']}</b>
                  </span>
               </>
            ),
         },
         {
            title: 'ไบนารี่',
            dataIndex: 'amount',
            key: 'amount',
            width: '2%',
            render: (value, record) => (
               <>
                  {value ? (
                     <>
                        <Link to={`/BonusBinaryDetail/${record.date}`}>
                           <span style={{ fontSize: '14px' }}>
                              <b> {commaNumber(value)}</b>
                           </span>
                        </Link>
                     </>
                  ) : (
                     <>
                        <p>-</p>
                     </>
                  )}
               </>
            ),
         },
         {
            title: 'โมบาย',
            dataIndex: 'comMo_amount',
            key: 'comMo_amount',

            width: '2%',
            render: (record) => (
               <>
                  {record ? (
                     <>
                        <span style={{ fontSize: '14px' }}>
                           <b> {commaNumber(record)}</b>
                        </span>
                     </>
                  ) : (
                     <>
                        <span>
                           <b>-</b>
                        </span>
                     </>
                  )}
               </>
            ),
         },

         {
            title: 'บริการ',
            dataIndex: ['amount', 'comMo_amount'],
            width: '2%',
            render: (_, row) => (
               <>
                  {row['comMo_amount'] ? (
                     <>
                        <span className='text-danger'>
                           <b>
                              -
                              {commaNumber(
                                 ((row['amount'] + row['comMo_amount']) *
                                    taxInt) /
                                    100
                              )}
                           </b>
                        </span>
                        <span>฿</span>

                        {/* -{(record * spPercentInt) / 100} */}
                     </>
                  ) : (
                     <>
                        <span className='text-danger'>
                           <b>
                              - {commaNumber((row['amount'] * taxInt) / 100)}
                           </b>
                        </span>
                        <span>฿</span>
                     </>
                  )}
               </>
            ),
         },

         {
            title: 'สุทธิ',
            width: '2%',
            dataIndex: ['amount', 'comMo_amount'],

            render: (_, row) => (
               <>
                  {row['amount'] && row['comMo_amount'] ? (
                     <>
                        <b>
                           {' '}
                           {commaNumber(
                              row['amount'] +
                                 row['comMo_amount'] -
                                 ((row['amount'] + row['comMo_amount']) *
                                    taxInt) /
                                    100
                           )}
                        </b>
                     </>
                  ) : (
                     <>
                        <b>
                           {' '}
                           <span>
                              {commaNumber(
                                 row['amount'] - (row['amount'] * taxInt) / 100
                              )}
                           </span>
                           <span>฿</span>
                        </b>
                     </>
                  )}
               </>
            ),
         },
         {
            title: 'สถานะ',
            responsive: ['sm'],
            width: '2%',
            render: (record) => {
               const { isPaid, status } = record;
               let color = 'red';
               let text = 'รอชำระ ⏳';

               if (isPaid === true) {
                  color = 'green';
                  text = 'แปลงเป็น ewallet ✅';
               } else if (status === 'รออนุมัติ') {
                  color = 'orange';
                  text = 'รออนุมัติ ⏳';
               } else if (status === 'โอนสำเร็จ') {
                  color = 'blue';
                  text = 'โอนสำเร็จ ✔️';
               }

               return (
                  <span style={{ fontWeight: 'bold', color }}>{text}</span>
               );
            },
         },
      ];
   } else if (isMatch) {
      columns = [
         {
            title: 'ลำดับ',
            dataIndex: 'depth',
            key: 'depth',
            render: (text, record, index) => <span>{index + 1}</span>,
            width: '2%',
         },
         {
            title: 'วันที่',
            dataIndex: 'date',
            width: '2%',
            key: 'date',
            render: (date) => (
               <div>
                  {' '}
                  <span style={{ fontSize: '14px' }}>
                     <b>{moment(date).format('DD/MM/YYYY')}</b>
                  </span>{' '}
               </div>
            ),
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
         },
         {
            title: 'ค่าบริหาร',
            width: '2%',
            dataIndex: ['position', 'amount2', 'amount22', 'amount23', 'date'],
            render: (_, row) => (
               <>
                  {row['position'] === 'Assistant' ||
                  row['position'] === 'Director' ? (
                     <>
                        {row['amount2'] ? (
                           <>
                              <b>
                                 {' '}
                                 <Link
                                    to={`/bonusmatchingsummary/${moment(
                                       row['date']
                                    ).format('DD-MM-YYYY')}
                                 /${row['amount22']}
                                 /${row['amount23']}

                                 `}
                                 >
                                    <span className='text-primary'>
                                       {commaNumber(
                                          Math.floor((row['amount2'] * 5) / 100)
                                       )}
                                    </span>
                                    <span>฿</span>
                                 </Link>
                              </b>
                           </>
                        ) : (
                           <span>1</span>
                        )}
                     </>
                  ) : null}
               </>
            ),
         },
      ];
   } else if (isMobile) {
      columns = [
         {
            title: 'ลำดับ',
            dataIndex: 'depth',
            key: 'depth',
            render: (text, record, index) => <span>{index + 1}</span>,
            width: '30%',
         },
         {
            title: 'วันที่',
            dataIndex: 'date',
            width: '30%',
            key: 'date',
            render: (date) => (
               <div>
                  {' '}
                  <span style={{ fontSize: '14px' }}>
                     <b>{moment(date).format('DD/MM/YYYY')}</b>
                  </span>{' '}
               </div>
            ),
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
         },

         {
            title: 'โมบาย',
            dataIndex: 'amount',
            key: 'amount',
            width: '30%',
            render: (value, record) => (
               <>
                  {value ? (
                     <>
                        <Link to={`/bonusMobileDetails/${record.date}`}>
                           <span style={{ fontSize: '14px' }}>
                              <b> {commaNumber(value)}</b>
                           </span>
                           <span>
                              <b>฿</b>
                           </span>
                        </Link>
                     </>
                  ) : (
                     <>
                        <p>-</p>
                     </>
                  )}
               </>
            ),
         },
      ];
   }

   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               {' '}
               <h3>
                  <b>สรุปค่าคอมมิชชั่น</b>
               </h3>
               {/* <RangePicker picker='week' /> */}
            </div>

            {loadingPage ? (
               <>
                  <Loading />{' '}
               </>
            ) : (
               <>
                  <div>
                     <div className='d-flex gap-3 mb-3'>
                        <Button
                           style={{
                              backgroundColor: isBinary ? '#ffca00' : '#14213D',
                              color: isBinary ? 'black' : 'white',
                           }}
                           size='large'
                           onClick={() => {
                              setIsBinary(true);
                              setIsMatch(false);
                              setIsMobile(false);
                           }}
                        >
                           {lang === 'TH'
                              ? 'คอมมิชชั่น'
                              : lang === 'ENG'
                              ? 'Commission'
                              : lang === 'KH'
                              ? 'កមវិធី'
                              : 'Commission'}
                        </Button>
                        <Button
                           style={{
                              backgroundColor: isMatch ? '#ffca00' : '#14213D',
                              color: isMatch ? 'black' : 'white',
                           }}
                           size='large'
                           onClick={() => {
                              setIsBinary(false);
                              setIsMatch(true);
                              setIsMobile(false);
                           }}
                        >
                           {lang === 'TH'
                              ? 'ค่าบริหาร'
                              : lang === 'ENG'
                              ? 'Management Fee'
                              : lang === 'KH'
                              ? 'ថ្លៃគ្រប់គ្រង'
                              : 'Management Fee'}
                        </Button>
                        <Button
                           style={{
                              backgroundColor: isMobile ? '#ffca00' : '#14213D',
                              color: isMobile ? 'black' : 'white',
                           }}
                           size='large'
                           onClick={() => {
                              setIsBinary(false);
                              setIsMatch(false);
                              setIsMobile(true);
                           }}
                        >
                           {lang === 'TH'
                              ? 'โมบาย'
                              : lang === 'ENG'
                              ? 'Mobile'
                              : lang === 'KH'
                              ? 'ម៉ូបៃល'
                              : 'Mobile'}
                        </Button>
                     </div>
                  </div>

                  {isBinary && (
                     <Table
                        rowClassName={(record) =>
                           record.isPaid ? 'paid-row' : ''
                        }
                        loadin={loadingPage}
                        columns={columns}
                        dataSource={mm}
                        scroll={{
                           x: 500,
                        }}
                        rowKey='id'
                     />
                  )}

                  {isMobile && (
                     <>
                        {isMobile && comMobile.length >= 1 && (
                           <>
                              <Table columns={columns} dataSource={comMobile} />
                           </>
                        )}
                     </>
                  )}

                  {isMatch && (
                     <>
                        {isMatch && mergedArray.length >= 1 && (
                           <>
                              <Table
                                 columns={columns}
                                 dataSource={mergedArray}
                              />
                           </>
                        )}
                     </>
                  )}
               </>
            )}
         </Container>
      </DefaultLayout>
   );
};

export default BonusHistory;
