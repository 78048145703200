import {
   Button,
   Col,
   Form,
   Image,
   Input,
   InputNumber,
   Result,
   Row,
   Select,
   Statistic,
   Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DefaultLayout from '../components/DefaultLayout';
import Resizer from 'react-image-file-resizer';

import styled from 'styled-components';

import Loading from '../components/Loading';
import BankTransaction from '../components/BankTransaction';
import { useDispatch, useSelector } from 'react-redux';

import { createDeposit, reset } from '../features/deposit/depositSlice';
import { getProducts } from '../features/product/productSlice';

import { FaTimes } from 'react-icons/fa';

import Bay from '../assets/icon/th/bay.svg';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import commaNumber from 'comma-number';
import { useNavigate } from 'react-router-dom';

import ModalConfirm from '../components/ModalConfirm';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { getPV } from '../features/ewallet/ewalletSlice';

const { Option } = Select;
const DepositPage = () => {
   const { ewallet: eWallet } = useSelector((state) => state.ewallet);
   moment.locale('th');

   const isMobile = useMediaQuery({ maxWidth: 370 });
   const isMobileL = useMediaQuery({ maxWidth: 500 });

   const [modalType, setModalType] = useState(null);
   const [content, setContent] = useState(null);

   const [isPayByEwallet, setIsPayByEwallet] = useState(false);
   const [ewallet, setEwallet] = useState(0);

   const [data, setData] = useState(null);

   const [finalPrice, setFinalPrice] = useState(0);

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);
   const { products } = useSelector((state) => state.product);
   const { deposit, isCreateSuccess, isLoading } = useSelector(
      (state) => state.deposit
   );

   let holderProducts = [];

   if (products.length >= 1) {
      holderProducts = products.filter((e) => e.type === 'Holder');
   }

   const [loadingPage, setLoadingPage] = useState(true);

   const [isBankSelected, setIsBankSelected] = useState(false);

   const [image, setImage] = useState('');
   const [media, setMedia] = useState(null);

   const [Holder, setHolder] = useState('');
   const [Tester, setTester] = useState('');

   const [form] = Form.useForm();

   useEffect(() => {
      dispatch(getProducts());

      dispatch(getPV());

      return () => {
         dispatch(reset());
      };
   }, [dispatch]);

   const handleProductImageUpload = (e) => {
      let fileInput = false;
      if (e.target.files[0]) {
         fileInput = true;
      }
      if (fileInput) {
         try {
            Resizer.imageFileResizer(
               e.target.files[0],
               500,
               500,
               'JPEG',
               100,
               0,
               (uri) => {
                  setMedia(uri);
               },
               'base64',
               500,
               500
            );
         } catch (err) {
            console.log(err);
         }
      }
   };

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (ewallet > eWallet.eWallet) {
         setContent(
            <div className='text-center'>
               <FaTimes size={128} color='red' />
               <h5>
                  <b>ยอด ewallet ที่ระบุเข้ามาเกิน ewallet ที่ท่านมี</b>
               </h5>
            </div>
         );
         setModalType('error');
      }

   }, [eWallet.eWallet, ewallet,loadingPage]);

   const onFinish = (values) => {
      const confirm = window.confirm('ยืนยันการเติม PV');

      const totalPVNumber = Math.floor(totalPV);

      if (confirm === true) {
         if (media !== null) values.imgSlip = media;

         values.userId = userInfo.userId;
         values.totalPV = totalPVNumber;
         values.totalPrice = finalPrice;
         values.totalProduct = totalQuantityPerProductArray;
         values.ewallet = ewallet;


         setContent(
            <div className='text-center'>
               <div className='text-center'>
                  {' '}
                  <TbAlertTriangleFilled
                     size={128}
                     color='#ffda30'
                     className='text-center'
                  />
               </div>
               <h5
                  style={{
                     fontSize: '18px',
                     textAlign: 'center',
                     color: 'red',
                  }}
               >
                  <b>ยืนยันการเติม PV</b>{' '}
               </h5>
            </div>
         );
         setModalType('confirm');

         setData(values);
   

         // dispatch(createDeposit(values));

         setMedia(null);
         form.resetFields();
      } else {
         window.location.reload();
      }
   };

   const handleConfirmOk = () => {
      setModalType(null);
      dispatch(createDeposit(data));
   };

   const [quantities, setQuantities] = useState({});

   // Function to handle quantity change
   const handleQuantityChange = (productId, value) => {
      setQuantities({ ...quantities, [productId]: value });
   };

   // Calculate total price and PV
   const totalPrice = Object.keys(quantities).reduce((acc, productId) => {
      const product = holderProducts.find((p) => p._id === productId);
      const quantity = quantities[productId];
      if (product && quantity) {
         return acc + product.price * quantity;
      }
      return acc;
   }, 0);

   const totalPV = Object.keys(quantities).reduce((acc, productId) => {
      const product = holderProducts.find((p) => p._id === productId);
      const quantity = quantities[productId];
      if (product && quantity) {
         return acc + product.pv * quantity;
      }
      return acc;
   }, 0);

   // Calculate total quantity for each product
   const totalQuantityPerProduct = holderProducts.reduce((acc, product) => {
      const quantity = quantities[product._id] || 0;
      if (quantity !== 0) {
         return { ...acc, [product.name]: quantity };
      }
      return acc;
   }, {});

   const totalQuantityPerProductArray = Object.entries(
      totalQuantityPerProduct
   ).map(([productName, quantity]) => ({
      name: productName.trim(),
      amount: quantity,
   }));

   const column = [
      {
         title: 'รูปภาพ',
         align: 'center',
         render: (value) => (
            <Image
               style={{ objectFit: 'contain' }}
               src={value.picUrl.url}
               width={100}
               height={100}
               preview={false}
            />
         ),
      },
      {
         title: 'รายการสินค้า',
         align: 'center',
         render: (value, record) => (
            <>
               <DivList style={{ fontSize: '18px' }}>
                  <div></div>
                  {isMobileL ? (
                     <DivList2 className='d-flex flex-column gap-2 align-items-end'>
                        <span style={{ fontSize: '16px', textAlign: 'end' }}>
                           {value.name}
                        </span>

                        <span style={{ color: 'orange' }}>
                           <Statistic
                              valueStyle={{ color: 'orange' }}
                              prefix='฿'
                              value={value.price}
                           />{' '}
                        </span>

                        <span>PV : {value.pv.toFixed(0)}</span>
                        <InputNumber
                           placeholder='0'
                           min={0}
                           defaultValue={quantities[record._id] || 0}
                           onChange={(value) =>
                              handleQuantityChange(
                                 record._id,
                                 value,
                                 record.name
                              )
                           }
                        />
                     </DivList2>
                  ) : (
                     <Row
                        justify='start'
                        align='start'
                        style={{ textAlign: 'start' }}
                     >
                        <Col md={12} lg={12} xl={12}>
                           {' '}
                           <div>
                              <span
                                 className='text-start'
                                 style={{ fontSize: '16px', textAlign: 'end' }}
                              >
                                 {value.name}
                              </span>
                           </div>
                        </Col>
                        <Col md={4} lg={4} xl={4}>
                           <span style={{ color: 'orange' }}>
                              <Statistic
                                 valueStyle={{ color: 'orange' }}
                                 prefix='฿'
                                 value={value.price}
                              />{' '}
                           </span>
                        </Col>
                        <Col md={4} lg={4} xl={4}>
                           <span> {value.pv.toFixed(0)}</span>
                        </Col>
                        <Col md={4} lg={4} xl={4}>
                           <InputNumber
                              placeholder='0'
                              min={0}
                              defaultValue={quantities[record._id] || 0}
                              onChange={(value) =>
                                 handleQuantityChange(
                                    record._id,
                                    value,
                                    record.name
                                 )
                              }
                           />
                        </Col>
                     </Row>
                  )}
               </DivList>
            </>
         ),
      },
   ];

   useEffect(() => {
      if (isCreateSuccess) {
         navigate(`/confirm-deposit/${deposit._id}`);
      }

      setFinalPrice(totalPrice);
   }, [dispatch, isCreateSuccess, deposit._id, navigate, totalPrice]);

   const [fileList, setFileList] = useState([]);

   const handleChange = ({ fileList }) => {
      setFileList(fileList);
   };

   const handleCloseModal = () => {
      setModalType(null);
      window.location.reload();
   };


   return (
      <DefaultLayout>
         {isLoading || loadingPage ? (
            <>
               <Loading />
            </>
         ) : (
            <>
               {' '}
               <Container>
                  {userInfo.isCenter ? (
                     <>
                        <h3 className='text-start mb-5 pt-5'>
                           <b>รายการสินค้าเติม PV</b>
                        </h3>
                     </>
                  ) : (
                     <h3 className='text-start mb-5 pt-5'>
                        <b>รายการสินค้า</b>
                     </h3>
                  )}

                  <div>
                     {' '}
                     <div>
                        <Table
                           style={{ maxWidth: '100%' }}
                           dataSource={holderProducts}
                           columns={column}
                           pagination={false}
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: 'white',
                           padding: '10px',
                           boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                        className='d-flex flex-column text-end mt-2'
                     >
                        <h2
                           className='text-start mb-3 mt-3'
                           style={{ fontSize: '20px' }}
                        >
                           <b>สรุปรายการสินค้า</b>
                        </h2>
                        <div>
                           <div className='d-flex justify-content-between'>
                              <h4 className='text-center'>
                                 <b>รวม PV</b>{' '}
                              </h4>
                              <h4 style={{ display: 'inline-block' }}>
                                 <span style={{ fontSize: '18px' }}>
                                    <b>{commaNumber(totalPV.toFixed(0))}</b>
                                 </span>
                              </h4>
                           </div>
                           <div className='d-flex justify-content-between'>
                              <h4 className='text-center'>
                                 <b> ยอดชำระเงิน </b>
                              </h4>
                              <h4 style={{ display: 'inline-block' }}>
                                 {' '}
                                 <span style={{ fontSize: '18px' }}>
                                    <b>
                                       ฿{commaNumber(finalPrice.toFixed(0))}{' '}
                                    </b>
                                 </span>
                              </h4>
                           </div>
                           {userInfo.isCenter && (
                              <h2
                                 style={{ fontSize: '16px' }}
                                 className='text-danger text-start'
                              >
                                 <b>
                                    {' '}
                                    หมายเหตุ ## ยอดซื้อ PV ขั้นต่ำ 10,000 PV{' '}
                                 </b>
                              </h2>
                           )}
                           <hr />

                           {userInfo.isCenter && (
                              <>
                                 <div className='text-start'>
                                    <strong style={{ fontSize: '20px' }}>
                                       สรุปรายการสินค้า
                                    </strong>

                                    <ul className='mt-2'>
                                       {totalQuantityPerProductArray.map(
                                          (product) => (
                                             <li
                                                style={{ listStyle: 'none' }}
                                                key={product.name}
                                             >
                                                <div className='d-flex flex-row justify-content-between '>
                                                   <b
                                                      style={{
                                                         fontSize: '18px',
                                                      }}
                                                   >
                                                      {product.name}{' '}
                                                   </b>
                                                   <b
                                                      style={{
                                                         fontSize: '16px',
                                                      }}
                                                   >
                                                      {product.amount}{' '}
                                                   </b>
                                                </div>
                                             </li>
                                          )
                                       )}
                                    </ul>
                                 </div>
                              </>
                           )}
                        </div>
                     </div>
                  </div>

                  {userInfo.isCenter && (
                     <>
                        {totalPV >= 10000 && (
                           <div
                              className=' mt-2 form-block-deposit'
                              style={{
                                 marginTop: 'calc(100vh - 200px)',
                                 paddingTop: '10px',
                              }}
                           >
                              <h2
                                 className='text-start mb-3'
                                 style={{ fontSize: '20px' }}
                              >
                                 <b>ช่องทางชำระเงิน</b>
                              </h2>
                              <Form
                                 form={form}
                                 onFinish={onFinish}
                                 name='basic'
                                 labelCol={{
                                    span: 4,
                                 }}
                                 wrapperCol={{
                                    span: 20,
                                 }}
                              >
                                 <Form.Item
                                    name='username'
                                    label={
                                       <span style={{ fontSize: '18px' }}>
                                          {' '}
                                          <b>สมาชิก</b>{' '}
                                       </span>
                                    }
                                 >
                                    <TextStyle>
                                       {userInfo.userId} - {userInfo.username}
                                    </TextStyle>
                                 </Form.Item>

                                 <Form.Item
                                    labelCol={{
                                       span: 4,
                                    }}
                                    wrapperCol={{
                                       span: 20,
                                    }}
                                    name='bank'
                                    label={
                                       <span style={{ fontSize: '18px' }}>
                                          {' '}
                                          <b>เลือกบัญชี</b>{' '}
                                       </span>
                                    }
                                 >
                                    <Select
                                       placeholder='กรุณาเลือกธนาคาร'
                                       allowClear
                                       onChange={(value) =>
                                          setIsBankSelected(value)
                                       }
                                       style={{
                                          width: '100%',
                                          fontSize: '24px !important',
                                       }}
                                    >
                                       <Option key={2} value='BAY-7101164370'>
                                          <div className='d-flex justify-content-between'>
                                             BAY-7101164370
                                             <Image
                                                style={{
                                                   backgroundColor: '#ddda33',
                                                }}
                                                src={Bay}
                                                width={25}
                                                preview={false}
                                             />
                                          </div>
                                       </Option>
                                    </Select>
                                 </Form.Item>

                                 {isBankSelected && (
                                    <>
                                       <Form.Item
                                          label={
                                             <span style={{ fontSize: '18px' }}>
                                                {' '}
                                                <b>ธนาคาร</b>{' '}
                                             </span>
                                          }
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 20,
                                          }}
                                       >
                                          <TextStyle>กรุงศรีอยุธทยา</TextStyle>
                                       </Form.Item>

                                       <Form.Item
                                          label={
                                             <span style={{ fontSize: '18px' }}>
                                                {' '}
                                                <b>ชื่อบัญชี</b>{' '}
                                             </span>
                                          }
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 20,
                                          }}
                                       >
                                          <TextStyle>
                                             บจก.สยามไพรพลัส(อินเตอร์)
                                          </TextStyle>
                                       </Form.Item>

                                       <Form.Item
                                          label={
                                             <Button
                                                onClick={() => {
                                                   setIsPayByEwallet(
                                                      (prev) => !prev
                                                   );
                                                }}
                                                className='btn-secondary'
                                             >
                                                ใช้ ewallet ชำระ
                                             </Button>
                                          }
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 20,
                                          }}
                                          name='ewallet'
                                          rules={[
                                             {
                                                required: false,
                                                message: ' nothing',
                                             },
                                             {
                                                validator: (_, value) => {
                                                   if (!value) {
                                                      return Promise.resolve();
                                                   }
                                                   if (value > ewallet) {
                                                      return Promise.reject(
                                                         new Error(
                                                            `ไม่สามารถกรอกจำนวนเกิน ฿${ewallet}`
                                                         )
                                                      );
                                                   }
                                                   if (value > totalPrice) {
                                                     
                                                      return Promise.reject(
                                                         new Error(
                                                            `ไม่สามารถกรอกจำนวนเกิน ฿${totalPrice}`
                                                         )
                                                      );
                                                   }
                                                   return Promise.resolve();
                                                },
                                             },
                                          ]}
                                       >
                                          {isPayByEwallet && (
                                             <InputNumber
                                                style={{ width: '100%' }}
                                                onChange={(value) => {
                                                   setEwallet(value);
                                                   setFinalPrice(
                                                      Math.max(
                                                         totalPrice - ewallet,
                                                         0
                                                      )
                                                   );
                                                }}
                                                placeholder='ewallet'
                                             />
                                          )}
                                       </Form.Item>

                                       <Form.Item
                                          label={
                                             <span
                                                style={{ fontSize: '20px' }}
                                                className='text-danger'
                                             >
                                                <b>ยอดที่ชำระเงินสด</b>
                                             </span>
                                          }
                                          labelCol={{ span: 4 }}
                                          wrapperCol={{ span: 20 }}
                                       >
                                          <TextStyle2>
                                             {commaNumber(
                                                Math.max(
                                                   totalPrice - ewallet,
                                                   0
                                                )
                                             )}
                                          </TextStyle2>
                                       </Form.Item>
                                    </>
                                 )}
                                 <>
                                    {userInfo.userId === '3131777' && (
                                       <>
                                          <input
                                             className='mb-3'
                                             id='upload'
                                             name='imgUpload'
                                             accept='image/*'
                                             type='file'
                                             onChange={handleProductImageUpload}
                                          />

                                          {media && (
                                             <div className='d-flex justify-content-center my-3'>
                                                <Image
                                                   className='m-auto'
                                                   height={200}
                                                   width={200}
                                                   src={media}
                                                />
                                                <FaTimes
                                                   style={{
                                                      cursor: 'pointer',
                                                      color: 'red',
                                                      fontSize: '16px',
                                                      marginLeft: '20px',
                                                   }}
                                                   onClick={() =>
                                                      setMedia(null)
                                                   }
                                                />
                                             </div>
                                          )}
                                       </>
                                    )}
                                 </>

                                 <Form.Item justify='center'>
                                    <Button
                                       block
                                       type='primary'
                                       size='large'
                                       className='my-4'
                                       htmlType='submit'
                                    >
                                       ขั้นตอนถัดไป
                                    </Button>
                                 </Form.Item>
                              </Form>
                           </div>
                        )}
                     </>
                  )}
               </Container>
               {modalType !== null && (
                  <ModalConfirm
                     open={!!modalType}
                     onCancel={handleCloseModal}
                     title={modalType === 'confirm' ? 'ยืนยันการเติม PV' : modalType === 'error' ? 'เกิดข้อผิดพลาด' : ''}
                     content={content}
                     onOk={handleConfirmOk}
                  />
               )}
            </>
         )}
      </DefaultLayout>
   );
};

const sizes = {
   mobileS: '320px',
   mobileM: '375px',
   mobileL: '558px',
   tablet: '768px',
   laptop: '1024px',
   laptopL: '1440px',
   laptopXL: '1740px',
   desktop: '2560px',
};

const device = {
   mobileS: `(max-width: ${sizes.mobileS})`,
   mobileM: `(max-width: ${sizes.mobileM})`,
   mobileL: `(max-width: ${sizes.mobileL})`,
   tablet: `(max-width: ${sizes.tablet})`,
   laptop: `(max-width: ${sizes.laptop})`,
   laptopL: `(max-width: ${sizes.laptopL})`,
   laptopXL: `(max-width: ${sizes.laptopXL})`,
   desktop: `(max-width: ${sizes.desktop})`,
};

const DivList = styled.div`
   @media ${device.laptopXL} {
      justify-content: start;
   }
   @media ${device.mobileL} {
      width: 100%;
      display: flex;
      justify-content: space-around;
      gap: calc(10%);
   }
`;

const DivList2 = styled.div`
   width: 200%;
   margin-right: 10px;
   @media ${device.mobileM} {
      width: 100%;
   }
`;

const TextStyle = styled.p`
   margin: 0 !important;
   border-bottom: 1px dashed;
   font-size: large;
`;

const TextStyle2 = styled.p`
   margin: 0 !important;
   border-bottom: 1px dashed;

   font-weight: bold;
   color: red;
   font-size: 20px;
`;

const ImagePreview = styled.div`
   margin: 1rem;
   padding: 2rem;
   border: 2px dashed #c8cbce;
   max-width: 300px;
   width: 100%;
   display: flex;
   align-items: center !important;
   justify-content: center !important;
   padding: 2rem;
   color: rgb(78, 78, 78);
   img {
      max-width: 100%;
   }
`;

export default DepositPage;
