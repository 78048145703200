import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
   getBonusWeak2,
   getUsersBonusDetail,
} from '../features/commission/commissionSlice';
import Loading from '../components/Loading';
import { Table, ConfigProvider } from 'antd';
import moment from 'moment';
import { v4 } from 'uuid';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6';
import 'moment/locale/th'; // Import the Thai locale
import WeekPicker from '../components/WeekPicker';
import commaNumber from 'comma-number';
import styled from 'styled-components';

// Set the locale to Thai
moment.locale('th');

const BonusesUsers = ({ onChange }) => {
   const dispatch = useDispatch();

   const [week2, setWeek2] = useState(null);

   const { userInfo } = useSelector((state) => state.auth);

   const { bonusDetail, isLoading } = useSelector((state) => state.commission);

   const [week, setWeek] = useState({
      firstDay: moment().startOf('isoWeek'),
      lastDay: moment().endOf('isoWeek'),
   });

   useEffect(() => {
      onChange && onChange(week);
   }, [week]);

   useEffect(() => {
      dispatch(getBonusWeak2(userInfo.userId));
   }, [dispatch]);

   let data = null;

   if (bonusDetail) {
      data = bonusDetail;
   }

   const columns = [
      {
         title: 'วันที่',
         dataIndex: 'date',
         width: '4%',
         key: 'date',
         render: (value, record) => {
            return record.children ? <b>{moment(value).format('DD/MM/YYYY')}</b> : null;
         },
      },

      {
         title: 'สมาชิก',
         dataIndex: 'user',
         width: '2%',
         key: 'user',
         render: (user) => {
            if (user && user.length) {
               return user.length + ' รหัส';
            } else if (user && user.userId) {
               return <b>{user.userId}</b>;
            } else {
               return '-';
            }
         },
         // render: (user) => (
         //    <b> {user.userId} </b>
         // )
      },
      {
         title: 'จำนวน',
         dataIndex: 'amount',
         width: '2%',
         key: 'amount',
         render: (value) => <b> {commaNumber(value)}</b>,
      },
   ];

   const groupDataByDate = (data) => {
      return data.reduce((acc, curr) => {
         const date = curr.date.split('T')[0];
         if (!acc[date]) {
            acc[date] = [];
         }
         acc[date].push(curr);
         return acc;
      }, {});
   };

   const prepareDataSource = (data) => {
      const groupedData = groupDataByDate(data);
      const sortedDates = Object.keys(groupedData).sort();

      return sortedDates.map((date, index) => {
         const children = groupedData[date].map((item, subIndex) => ({
            key: `${date}-${subIndex}`,
            ...item,
         }));

         const totalAmount = children.reduce(
            (sum, item) => sum + item.amount,
            0
         );
         const uniqueUsers = new Set(children.map((item) => item.user.userId))
            .size;

         return {
            key: index,
            date: date,
            userCount: uniqueUsers + ' รหัส',
            totalAmount: totalAmount,
            children: children,
         };
      });
   };

   const dataSource = prepareDataSource(data);

   const totalAmount = bonusDetail.reduce(
      (sum, record) => sum + record.amount,
      0
   );

   const handleWeekChange = ({ startOfWeek, endOfWeek }) => {
      const newWeek = { startOfWeek, endOfWeek };
      setWeek2(newWeek);

      const queryString = encodeURIComponent(JSON.stringify(newWeek));
      dispatch(getUsersBonusDetail(queryString));
   };

   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               <h3>
                  <b>สรุปค่าคอมมิชชั่นรายสัปดาห์</b>
               </h3>
            </div>

            {isLoading ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  <div className='d-flex flex-row gap-3 justify-content-center'>
                     <WeekPicker
                        className='justify-center'
                        onWeekChange={handleWeekChange}
                     />
                     {week2 && (
                        <>
                           <span>
                              <b>
                                 {moment(week2.startOfWeek).format(
                                    'DD/MM/YYYY'
                                 )}{' '}
                                 -{' '}
                                 {moment(week2.endOfWeek).format('DD/MM/YYYY')}
                              </b>
                           </span>
                        </>
                     )}
                  </div>
                  <br />
                  {dataSource.length >= 1 ? (
                     <>
                        <Table
                           columns={columns}
                           dataSource={dataSource}
                           pagination={false} // Optional: add pagination if you have a lot of data
                        />
                        <br />
                        <Card className=''>
                           <h5 className='text-center'>
                              {' '}
                              <span>
                                 <b>ยอดรวมโบนัสรายสัปดาห์</b> ={' '}
                              </span>{' '}
                              <b>{commaNumber(totalAmount)} บาท</b>
                           </h5>
                        </Card>
                     </>
                  ) : (
                     <>
                        <Card className='text-center'>
                           <span style={{ fontSize: '18px' }} className=''>
                              <b>เลือกวันเพื่อแสดงข้อมูล</b>
                           </span>
                        </Card>
                     </>
                  )}
               </>
            )}
         </Container>
      </DefaultLayout>
   );
};

const Card = styled.div`
   background-color: ${(props) => props.backgroundColor || '#fff'};
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 16px;
   width: 350px;
   height: auto;
   margin-left: auto;
   margin-right: auto;
`;

export default BonusesUsers;
