import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Col, Row, Skeleton } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { getEwallet } from '../features/ewallet/ewalletSlice';
import moment from 'moment';
import { Container } from 'react-bootstrap';

import commaNumber from 'comma-number';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { RiArrowLeftDoubleFill, RiArrowRightDoubleFill } from 'react-icons/ri';

const EwalletHistory = () => {
   moment.locale('th');
   const dispatch = useDispatch();
   const lang = useSelector((state) => state.language.lang);
   const { ewallet2, isLoading } = useSelector((state) => state.ewallet);

   const [page, setPage] = useState(1);

   useEffect(() => {
      if (page >= 1) {
         dispatch(getEwallet(page));
      }
   }, [dispatch, page]);

   const handlePreviousPage = () => {
      setPage((prevPage) => Math.max(prevPage - 1, 1));
   };

   const handleNextPage = () => {
      if (ewallet2.currentPage < ewallet2.totalPages) {
         setPage((prevPage) => prevPage + 1);
      }
   };
   const handleFirstPage = () => {
      setPage(1);
   };

   const handleLastPage = () => {
      setPage(ewallet2.totalPages);
   };

   const translations = {
      TH: {
        'ยกเลิกใบเสร็จ': 'ยกเลิกใบเสร็จ',
        'สมัครสมาชิก': 'สมัครสมาชิก',
      },
      ENG: {
        'ยกเลิกใบเสร็จ': 'Cancel Receipt',
        'สมัครสมาชิก': 'Register',
      },
      KH: {
        'ยกเลิกใบเสร็จ': 'បោះបង់វិក័យបត្រ',
        'สมัครสมาชิก': 'ចុះឈ្មោះ',
      },
    };
    
    const getTranslation = (text, lang) => translations[lang][text] || text;

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Container>
                  <Skeleton active /> <Skeleton active /> <Skeleton active />{' '}
               </Container>
            </>
         ) : (
            <>
               <Container>
                  <div>
                     <Row align='center'>
                        <Col lg={16}>
                           <div className='my-5'>
                              <h3 className='text-center'>
                                 <b>
                                    {lang === 'TH'
                                       ? 'ประวัติ ewallet'
                                       : lang === 'ENG'
                                       ? 'ewallet History'
                                       : lang === 'KH'
                                       ? 'ប្រវត្តិ ewallet'
                                       : 'ewallet History'}
                                 </b>
                              </h3>
                           </div>
                           {ewallet2 && (
                              <div>
                                 <Row justify='space-between'>
                                    {ewallet2?.actions?.map((e) => (
                                       <Col xs={24} md={24} key={e._id}>
                                          <div className='d-flex flex-row justify-content-between'>
                                             <div className='d-flex flex-column'>
                                                <div>
                                                   <span
                                                      className={
                                                         [
                                                       
                                                            'สมัครสมาชิก',
                                                            'ต่ออายุรหัส',
                                                            'ท็อปอัพรหัส',
                                                         ].includes(e.note)
                                                            ? 'text-danger'
                                                            : ''
                                                      }
                                                   >
                                                      <b>
                                                         {getTranslation(
                                                            e.note,
                                                            lang
                                                         )}
                                                      </b>
                                                   </span>
                                                   {e.transfer === false ? (
                                                 <>
                                                      <span>
                                                         <b>: {e?.userRecieve?.userId}</b>
                                                      </span>
                                                      {e.note === 'cancel order' && (
                                                         <span>
                                                            <b>
                                                               :{' '}
                                                               {
                                                                  e.userId
                                                               }
                                                            </b>
                                                         </span>
                                                      )}
                                                 </>
                                                   ) : (
                                                      <span>
                                                         <b>
                                                            :{' '}
                                                            {
                                                               e.userTransfer
                                                                  ?.userId
                                                            }
                                                         </b>
                                                      </span>
                                                   )}
                                                </div>
                                               {e.transfer === false ? (
                                                <> <span className='mb-3'>
                                                <b>{e.userRecieve?.name}</b>
                                             </span></>
                                               ) : (
                                                <> <span className='mb-3'>
                                                <b>{e.userTransfer?.name}</b>
                                             </span></>
                                               )}

                                                <span
                                                   style={{ fontSize: '14px' }}
                                                >
                                                   <b>
                                                      {moment(e.date).format(
                                                         'LLL'
                                                      )}
                                                   </b>
                                                </span>
                                                {e.note === 'ยกเลิกใบเสร็จ' && (
                                                   <span>
                                                      สถานะ <b>{e.note}</b>
                                                   </span>
                                                )}
                                             </div>
                                             <div className='text-end'>
                                                <span className='mb-3'>
                                                   {e.transfer ? (
                                                      <span>
                                                         <b className='text-danger'>
                                                            -
                                                         </b>{' '}
                                                         <span className='text-danger'>
                                                            <b>
                                                               {commaNumber(
                                                                  e.amount
                                                               )}
                                                            </b>
                                                         </span>
                                                      </span>
                                                   ) : (
                                                      <span>
                                                         <b
                                                            style={{
                                                               color: '#2626dd',
                                                            }}
                                                         >
                                                            +
                                                         </b>{' '}
                                                         <span
                                                            style={{
                                                               color: '#2626dd',
                                                            }}
                                                         >
                                                            <b>
                                                               {commaNumber(
                                                                  e.amount
                                                               )}
                                                            </b>
                                                         </span>
                                                      </span>
                                                   )}
                                                </span>
                                                <br />
                                                <span>
                                                   <b>
                                                      {commaNumber(
                                                         e.previosAmount
                                                      )}
                                                   </b>
                                                </span>
                                             </div>
                                          </div>
                                          <hr />
                                       </Col>
                                    ))}
                                 </Row>

                                 {/* Pagination Controls */}
                                 <div className='d-flex justify-content-around mt-2 mb-5'>
                                    <RiArrowLeftDoubleFill
                                       className='cursor-pointer'
                                       onClick={handleFirstPage}
                                       disabled={page === 1}
                                       size={32}
                                    ></RiArrowLeftDoubleFill>
                                    <FaArrowLeft
                                       className='cursor-pointer'
                                       onClick={handlePreviousPage}
                                       disabled={page === 1}
                                       size={32}
                                    ></FaArrowLeft>
                                    <span>
                                       <b>
                                          หน้า {ewallet2.currentPage} จาก{' '}
                                          {ewallet2.totalPages}
                                       </b>
                                    </span>

                                    <FaArrowRight
                                       className='cursor-pointer'
                                       onClick={handleNextPage}
                                       disabled={
                                          ewallet2.currentPage ===
                                          ewallet2.totalPages
                                       }
                                       size={32}
                                    ></FaArrowRight>
                                    <RiArrowRightDoubleFill
                                       className='cursor-pointer'
                                       onClick={handleLastPage}
                                       disabled={
                                          ewallet2.currentPage ===
                                          ewallet2.totalPages
                                       }
                                       size={32}
                                    ></RiArrowRightDoubleFill>
                                 </div>
                              </div>
                           )}
                        </Col>
                     </Row>
                  </div>
               </Container>
            </>
         )}
      </DefaultLayout>
   );
};

export default EwalletHistory;
