import { Button, Form, Input, Modal, InputNumber, Alert } from 'antd';
import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';

import { useDispatch, useSelector } from 'react-redux';
import { getPV, transferEwallet } from '../features/ewallet/ewalletSlice';
import SearchInputForEwallet from '../components/form/SearchInputForEwallet';

import commaNumber from 'comma-number';

import { TbAlertTriangleFilled } from 'react-icons/tb';
import { GiConfirmed } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

const TransferEwallet = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate()

   
      const { userInfo } = useSelector((state) => state.auth);

   const lang = useSelector((state) => state.language.lang);
   const [modalSuccess, setModalSuccess] = useState(false);

   const { ewallet, isError, message, isTransferSuccess, isLoading } =
      useSelector((state) => state.ewallet);

   const [recommendedBy, setRecommendedBy] = useState('');
   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   const sendDataToParent = (i) => {
      setRecommendedBy(i.userId);
   };

   const [loadingPage, setLoadingPage] = useState(true);

   const [modalOpen, setModalOpen] = useState(false);

   const [data, setData] = useState({
      eWallet: 0,
      id: '',
   });

   

   useEffect(() => {

      if(userInfo.userId !== '7779739'){
         navigate('/')
      }

      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isTransferSuccess) {
         setModalSuccess(true);
      }

      dispatch(getPV());
   }, [dispatch, isTransferSuccess]);

   let eWallet = 0;
   if (ewallet.eWallet > 1) {
      eWallet = ewallet.eWallet;
   }


   const onFinish = (values) => {
      values.id = recId;

      if (values) {
         setData({ eWallet: values.ewallet, id: recId });
      }

      if (values.ewallet > eWallet) {
         window.alert('ไม่สามารถทำการโอนได้ ยอด wallet ไม่พอ');
      } else {
         setModalOpen(true);
      }
   };

   const handleConfirm = (e) => {
      if (data.eWallet > eWallet) {
         window.alert('ไม่สามารถทำการโอนได้ ยอด wallet ไม่พอ');
      } else {
         const confirm = window.confirm('ยืนยันโอน PV');
         if (confirm === true) {
            dispatch(transferEwallet(data));

            setModalOpen(false);
         }
      }
   };

   const newEwallet = commaNumber(eWallet);

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               {' '}
               <Container>
                  <div
                     style={{
                        borderTop: '80px solid #14123D',
                        position: 'relative',
                     }}
                     className=' mt-5 form-block-transfer'
                  >
                     {isError && (
                        <Alert
                           message={message}
                           type='error'
                           closable
                           showIcon
                        />
                     )}
                     <br />

                     <h3
                        className='text-center mb-5 '
                        style={{
                           position: 'absolute',
                           right: '0',
                           left: '0',
                           top: '-55px',
                           color: '#ffca00',
                        }}
                     >
                        {lang === 'TH' && <b>โอน EWALLET</b>}
                        {lang === 'ENG' && <b>Transfer Ewallet</b>}
                        {lang === 'KH' && <b>ផ្ទេរ​ពិន្ទុ Ewallet</b>}
                     </h3>
                     <Form
                        name='basic'
                        labelCol={{
                           span: 6,
                        }}
                        wrapperCol={{
                           span: 18,
                        }}
                        initialValues={{
                           remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete='off'
                     >
                        <Form.Item
                           label={
                              lang === 'TH'
                                 ? 'รหัสสมาชิกที่รับโอน'
                                 : lang === 'ENG'
                                 ? 'Recipient Member Code'
                                 : lang === 'KH'
                                 ? 'លេខសមាជិកដែលទទួល'
                                 : 'Recipient Member Code'
                           }
                        >
                           <SearchInputForEwallet
                              upLineData={recId}
                              value={recommendedBy}
                              sendDataToParent={sendDataToParent}
                           />
                           <Button
                              type='danger'
                              onClick={() => {
                                 localStorage.removeItem('recomId');
                                 localStorage.removeItem('recomName');
                                 window.location.reload();
                              }}
                           >
                              CLEAR
                           </Button>
                        </Form.Item>

                        <Form.Item
                           label={
                              lang === 'TH'
                                 ? 'จำนวน Ewallet ปัจจุบัน'
                                 : lang === 'ENG'
                                 ? 'Current Ewallet Amount'
                                 : lang === 'KH'
                                 ? 'ចំនួន Ewallet បច្ចុប្បន្ន'
                                 : 'Current Ewallet Amount'
                           }
                           style={{ color: 'black' }}
                        >
                           <Input
                              disabled
                              style={{ color: 'black', fontSize: '18px' }}
                              placeholder={` ${newEwallet} ฿`}
                           />
                        </Form.Item>

                        {recId ? (
                           <Form.Item
                              label='Ewallet'
                              name='ewallet'
                              rules={[
                                 {
                                    required: true,
                                    message: 'กรอกณากรอกจำนวนเงิน!',
                                 },
                              ]}
                           >
                              <InputNumber
                                 style={{ width: '100%' }}
                                 formatter={(value) =>
                                    ` ${value}`.replace(
                                       /\B(?=(\d{3})+(?!\d))/g,
                                       ','
                                    )
                                 }
                                 parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                 }
                              />
                           </Form.Item>
                        ) : null}

                        <div>
                           <Button
                              type='primary'
                              htmlType='submit'
                              block
                              size='large'
                              className='btn-secondary mt-3'
                           >
                              {lang === 'TH' && 'ยืนยัน'}
                              {lang === 'ENG' && 'Confirm'}
                              {lang === 'KH' && 'បញ្ជាក់'}
                           </Button>
                        </div>
                     </Form>
                  </div>
               </Container>
               <Modal
                  title={
                     lang === 'TH'
                        ? 'โอน Ewallet สำเร็จ'
                        : lang === 'ENG'
                        ? 'Ewallet Transfer Successful'
                        : lang === 'KH'
                        ? 'ផ្ទេរ Ewallet បានជោគជ័យ'
                        : 'Ewallet Transfer Successful'
                  }
                  style={{ height: '60%' }}
                  visible={modalSuccess}
                  footer={false}
                  onCancel={() => {
                     setModalSuccess(false);
                     localStorage.removeItem('recomId');
                     localStorage.removeItem('recomName');
                     window.location.reload();
                  }}
               >
                  <div className='text-center mb-3'>
                     <GiConfirmed size={64} color='#4cbf2d' />
                  </div>
                  <h4
                     style={{ fontSize: '30px' }}
                     className='text-center mb-4 text-danger'
                  >
                     {' '}
                     <b>
                        {lang === 'TH' && 'โอน Ewallet สำเร็จ'}
                        {lang === 'ENG' && 'Ewallet Transfer Successful'}
                        {lang === 'KH' && 'ផ្ទេរ Ewallet បានជោគជ័យ'}
                     </b>{' '}
                  </h4>

                  <h5 className='text-center'>
                     <b>
                        {' '}
                        {lang === 'TH' && `รหัสสมาชิกรับโอน: ${recId}`}
                        {lang === 'ENG' && `Recipient Member Code: ${recId}`}
                        {lang === 'KH' && `លេខសមាជិកដែលទទួល: ${recId}`}
                     </b>
                  </h5>
                  <h5 className='text-center'>
                     <b>
                        {' '}
                        {lang === 'TH' && `ชื่อสมาชิกรับโอน: ${recName}`}
                        {lang === 'ENG' && `Recipient Member Name: ${recName}`}
                        {lang === 'KH' && `ឈ្មោះសមាជិកដែលទទួល: ${recName}`}
                     </b>
                  </h5>

                  <h5 className='text-center'>
                     <b>
                        ewallet:{' '}
                        <span className='text-danger'>
                           {commaNumber(data.eWallet)}
                        </span>
                     </b>
                  </h5>

                  <br />
               </Modal>
               <Modal
                  title={
                     lang === 'TH'
                        ? 'ยืนยันการโอน eWallet'
                        : lang === 'ENG'
                        ? 'Confirm eWallet Transfer'
                        : lang === 'KH'
                        ? 'បញ្ជាក់ការផ្ទេរ eWallet'
                        : 'Confirm eWallet Transfer'
                  }
                  visible={modalOpen}
                  footer={false}
                  onCancel={() => {
                     setModalOpen(false);
                  }}
               >
                  <div className='text-center'>
                     {' '}
                     <TbAlertTriangleFilled
                        size={128}
                        color='#ffda30'
                        className='text-center'
                     />
                  </div>
                  <h5
                     style={{
                        fontSize: '30px',
                        textAlign: 'center',
                        color: 'red',
                     }}
                  >
                     <b>
                        {lang === 'TH' && 'ยืนยันการโอน Ewallet'}
                        {lang === 'ENG' && 'Confirm Ewallet Transfer'}
                        {lang === 'KH' && 'បញ្ជាក់ការផ្ទេរ Ewallet'}
                     </b>
                  </h5>
                  <p
                     className='mb-2'
                     style={{ fontSize: '16px', textAlign: 'center' }}
                  >
                     <b>
                        {lang === 'TH' &&
                           'คุณยืนยันต้องการโอน Ewallet ให้กับสมาชิก หรือ ไม่'}
                        {lang === 'ENG' &&
                           'Do you confirm to transfer Ewallet to the member?'}
                        {lang === 'KH' &&
                           'តើអ្នកបញ្ជាក់ថាចង់ផ្ទេរ Ewallet ទៅសមាជិកឬទេ?'}
                     </b>
                  </p>
                  <p
                     className='mb-0'
                     style={{ fontSize: '16px', textAlign: 'center' }}
                  >
                     <b>
                        {lang === 'TH' && 'รหัสสมาชิกรับโอน : '}
                        {lang === 'ENG' && 'Recipient Member ID: '}
                        {lang === 'KH' && 'លេខសម្គាល់សមាជិកដែលទទួល: '}
                        <span className='text-danger'>{recId}</span>
                     </b>
                  </p>
                  <p
                     className='mb-0'
                     style={{ fontSize: '16px', textAlign: 'center' }}
                  >
                     <b>
                        {lang === 'TH' && 'ชื่อสมาชิกรับโอน : '}
                        {lang === 'ENG' && 'Recipient Member Name: '}
                        {lang === 'KH' && 'ឈ្មោះសមាជិកដែលទទួល: '}
                        <span className='text-danger'>{recName}</span>
                     </b>
                  </p>

                  <p style={{ fontSize: '16px', textAlign: 'center' }}>
                     <b>
                        ewallet :
                        <span className='text-danger ml-1'>
                           {commaNumber(data.eWallet)}
                        </span>
                     </b>
                  </p>
                  <br />
                  <div className='d-flex'>
                     <br />
                     <br />
                     <Button
                        style={{ backgroundColor: '#ffda30' }}
                        block
                        size='large'
                        onClick={() => setModalOpen(false)}
                     >
                        <h5>
                           <b>
                              {lang === 'TH' && 'ยกเลิก'}
                              {lang === 'ENG' && 'Cancel'}
                              {lang === 'KH' && 'បោះបង់'}
                           </b>
                        </h5>
                     </Button>{' '}
                     <Button
                        block
                        size='large'
                        type='primary'
                        htmlType='submit'
                        onClick={handleConfirm}
                     >
                        <h5 style={{ color: '#ffda30' }}>
                           <b>
                              {lang === 'TH' && 'ยืนยัน'}
                              {lang === 'ENG' && 'Confirm'}
                              {lang === 'KH' && 'បញ្ជាក់'}
                           </b>
                        </h5>
                     </Button>
                  </div>
               </Modal>
            </>
         )}
      </DefaultLayout>
   );
};

export default TransferEwallet;
