import React from 'react';
import { Button, Form, Input, Modal, InputNumber, Alert } from 'antd';
import { GiConfirmed } from 'react-icons/gi';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { FaTimes } from 'react-icons/fa';

const ModalConfirm = ({
   open,
   onOk,
   onCancel,
   modalType,
   title,
   content,
   totalAmount,
}) => {


   return (
      <Modal title={title} visible={open} onOk={onOk} onCancel={onCancel}>
      
         <div>
          {content}
         </div>
         {modalType === 'confirm' && totalAmount > 1 && (
            <p className='text-center ' style={{ fontSize: '18px' }}>
               <b>จำนวนที่แปลง</b>:
               <span style={{ fontSize: '18px', marginLeft: '.25rem' }}>
                  <b>{totalAmount}</b>
               </span>
            </p>
         )}
      </Modal>
   );
};

export default ModalConfirm;
