import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getBonusWeak2 } from '../features/commission/commissionSlice';
import moment from 'moment';
import commaNumber from 'comma-number';
import { Button, Card, Checkbox, Table } from 'antd';
import Loading from '../components/Loading';
import { Container } from 'react-bootstrap';
import { convertEwallet, reset } from '../features/ewallet/ewalletSlice';
import ModalConfirm from '../components/ModalConfirm';
import { FaTimes } from 'react-icons/fa';
import { GiConfirmed } from 'react-icons/gi';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const Ewallet = () => {
   const dispatch = useDispatch();
   const navigate= useNavigate()

   let totalAmount = 0;

   const [content, setContet] = useState(null);

   const [modalType, setModalType] = useState(null);

   const [bonusSelected, setBonusSelected] = useState([]);

   const { userInfo } = useSelector((state) => state.auth);

   const { bonusWeak2, isLoading } = useSelector((state) => state.commission);
   const { isConvertFail, isConvertSuccess, message } = useSelector(
      (state) => state.ewallet
   );

   useEffect(() => {
      dispatch(getBonusWeak2(userInfo.userId));

      if(userInfo.userId !== '7779739'){
         navigate('/')
      }

   }, [dispatch, userInfo]);

   const handleCheckboxChange = (e, record) => {
      setBonusSelected((prevSelected) => {
         if (e.target.checked) {
            return [...prevSelected, record]; // Add row to selection
         } else {
            return prevSelected.filter((item) => item._id !== record._id); // Remove row
         }
      });
   };


   const columns = [
      {
         title: 'วันที่',
         dataIndex: 'date',
         width: '4%',
         key: 'date',
         render: (value, record) => {
            return record.children ? <b>{moment(value).format('DD/MM/YYYY')}</b> : null;
         },
      },

      {
         title: 'รหัส',
         dataIndex: 'user',
         width: '2%',
         key: 'user',
         render: (user) => {
            if (Array.isArray(user)) {
               return user.length + ' รหัส';
            } else if (user?.userId) {
               return <b>{user.userId}</b>;
            }
            return '';
         }
         
         
      },
      {
         title: 'จำนวน',
         dataIndex: 'amount',
         width: '2%',
         key: 'amount',
         render: (value) => value ? <b>{commaNumber(value)} บาท</b> : null

      },
      {
         title: 'action',
         width: '2%',
         render: (record) => {
            if (record.amount && record.isPaid !== true) {
               return (
                  <Checkbox
                     checked={bonusSelected.some(
                        (item) => item._id === record._id
                     )}
                     onChange={(e) => handleCheckboxChange(e, record)}
                  >
                     แปลงเป็น ewallet
                  </Checkbox>
               );
            }
         },
      },
   ];

   const groupDataByDate = (data) => {
      return data.reduce((acc, curr) => {
         const date = curr.date.split('T')[0];
         if (!acc[date]) {
            acc[date] = [];
         }
         acc[date].push(curr);
         return acc;
      }, {});
   };

   const prepareDataSource = (bonus) => {
      const groupedData = groupDataByDate(bonus);
      const sortedDates = Object.keys(groupedData).sort();

      return sortedDates.map((date, index) => {
         const children = groupedData[date].map((item, subIndex) => ({
            key: `${date}-${subIndex}`,
            ...item,
         }));

         const totalAmount = children.reduce(
            (sum, item) => sum + item.amount,
            0
         );
         const uniqueUsers = new Set(children.map((item) => item.user.userId))
            .size;

         return {
            key: index,
            date: date,
            userCount: uniqueUsers + ' รหัส',
            totalAmount: totalAmount,
            children: children,
         };
      });
   };

   const dataSource = prepareDataSource(bonusWeak2);

   const totalAmount2 = bonusSelected.reduce(
      (sum, record) => sum + record.amount,
      0
   );

   const handlerConvert = () => {
      totalAmount = bonusSelected.reduce(
         (sum, acc) => sum + acc.amount * (1 - 0.05),
         0
      );

      setContet(
         <div className='text-center'>
            <div className='text-center'>
               {' '}
               <TbAlertTriangleFilled
                  size={128}
                  color='#ffda30'
                  className='text-center'
               />
            </div>
            <h5
               style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  color: 'red',
               }}
            >
               <b>
                  {' '}
                  หากท่านทำการแปลงโบนัสเป็น e-wallet
                  จะไม่สามารถย้อนกลับมาเป็นโบนัสได้อีก
               </b>{' '}
            </h5>
         </div>
      );
      setModalType('confirm');
   };

   const handleConfirmOk = () => {
      setModalType(null);
      dispatch(convertEwallet(bonusSelected));
   };

   const handleCloseModal = () => {
      setModalType(null);
      window.location.reload();
   };
   useEffect(() => {
      if (isConvertFail) {
         setContet(
            <div className='text-center'>
               <FaTimes size={128} color='red' />
               <h5>
                  <b>{message}</b>
               </h5>
            </div>
         );
         setModalType('error');
      }
      if (isConvertSuccess) {
         setContet(
            <div>
               <div className='text-center mb-3'>
                  <GiConfirmed size={64} color='#4cbf2d' />
               </div>

               <h4
                  style={{ fontSize: '30px' }}
                  className='text-center mb-4 text-danger'
               >
                  {' '}
                  <b>ทำรายการ สำเร็จ</b>{' '}
               </h4>
            </div>
         );
         setModalType('success');
      }

      return () => {
         dispatch(reset());
      };
   }, [isConvertFail, isConvertSuccess, message, dispatch]);



   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               <h3>
                  <b>จัดการ ewallet</b>
               </h3>
            </div>

            {isLoading ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  <div className='d-flex flex-row gap-3 justify-content-center'></div>
                  <br />
                  {dataSource.length >= 1 && (
                     <>
                        <Table
                           columns={columns}
                           dataSource={dataSource}
                           
                           pagination={false} 
                        />
                        <br />
                        <Card className=''>
                           <h5 className='text-center'>
                              {' '}
                              <span>
                                 <b>ยอดรวมโบนัสรายสัปดาห์</b> ={' '}
                              </span>{' '}
                              <b>{commaNumber(totalAmount2)} บาท</b>
                           </h5>
                        </Card>

                        {bonusSelected.length >= 1 && (
                           <div className='d-flex justify-content-center mt-4'>
                              <Button onClick={handlerConvert} type='primary'>
                                 แปลงเงินเป็น ewallet
                              </Button>
                           </div>
                        )}

                        {modalType !== null && (
                           <ModalConfirm
                              open={!!modalType}
                              onOk={
                                 modalType === 'confirm'
                                    ? handleConfirmOk
                                    : handleCloseModal
                              }
                              onCancel={handleCloseModal}
                              modalType={modalType}
                              title={
                                 modalType === 'confirm'
                                    ? 'ยืนยันการแปลงเงิน'
                                    : modalType === 'success'
                                    ? 'สำเร็จ'
                                    : 'ทำรายการไม่สำเร็จ'
                              }
                              content={content}
                              totalAmount={totalAmount2}
                           />
                        )}
                     </>
                  )}
               </>
            )}
         </Container>
      </DefaultLayout>
   );
};

export default Ewallet;
